import React from 'react';
import Recipes from './recipe/Recipes'

class Main extends React.Component {
  render(){
    return (
      <div>
        <Recipes />
      </div>
    )
  }

}

export default Main;
